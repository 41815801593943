<template>
    <div class="header-box">
        <div class="header">
            <div class="header-left">
                <div>服务热线：4008873528</div>
            </div>
            <div class="contact-box">
                <div class="iconfont icon-facebook"></div>
                <div class="iconfont icon-Youtube"></div>
                <div class="iconfont icon-instagram-line"></div>
                <div class="iconfont icon-douyin-"></div>
            </div>
        </div>

        <div class="nav">
            <div class="meau-box">
                <div class="iconfont icon-ego-menu" @click="meauSwitch"></div>

                <div class="meau-list" v-show="meauShow">
                    <div class="meau-item" @click="navigatorTo" data-url="/">Home</div>
                    <div class="meau-item" @click="navigatorTo" data-url="/AboutUs">About Us</div>
                    <div class="meau-item" @click="navigatorTo" data-url="/Shop">Classify</div>
                    <div class="meau-item" @click="navigatorTo" data-url="/FAQs">FAQs</div>
                    <div class="meau-item" @click="navigatorTo" data-url="/ContactUs">Contact Us</div>
                    <!-- <div class="meau-item" @click="navigatorTo" data-url="/Culture">Brand Culture</div> -->
                    <div class="meau-item" @click="navigatorTo" data-url="/Distribution">分销(大陆地区)</div>
                </div>

            </div>

            <div class="logo-box">
                <img class="logo" @click="navigatorTo" data-url="/HomePage" src="../assets/vesitinLogo.webp" alt="">
            </div>

            <div class="nav-list">

                <div class="nav-item">
                    <div @click="navigatorTo" data-url="/HomePage">Home</div>
                    <div @click="navigatorTo" data-url="/HomePage">首页</div>
                </div>
                <div class="nav-item">
                    <div @click="navigatorTo" data-url="/AboutUs">About Us</div>
                    <div @click="navigatorTo" data-url="/AboutUs">关于我们</div>
                </div>
                <div class="nav-item">
                    <div @click="navigatorTo" data-url="/Shop">Product</div>
                    <div @click="navigatorTo" data-url="/Shop">产品介绍</div>
                </div>
                <div class="nav-item">
                    <div @click="navigatorTo" data-url="/FAQs">FAQs</div>
                    <div @click="navigatorTo" data-url="/FAQs">常见问题</div>
                </div>
                <div class="nav-item">
                    <div @click="navigatorTo" data-url="/ContactUs">Contact Us</div>
                    <div @click="navigatorTo" data-url="/ContactUs">联系我们</div>
                </div>
                <!-- <div class="nav-item">
                    <div @click="navigatorTo" data-url="/Culture">Brand Culture</div>
                    <div @click="navigatorTo" data-url="/Culture">品牌文化</div>
                </div> -->
                <div class="nav-item">
                    <div @click="navigatorTo" data-url="/Distribution">distribution</div>
                    <div @click="navigatorTo" data-url="/Distribution">大陆分销</div>
                </div>
            </div>
            <div class="login-box" @click="navigatorTo" data-url="/Login">Login</div>
        </div>
    </div>

</template>


<script>
export default {
    name: 'myHeader',
    data() {
        return {
            meauShow: false,
        }
    },

    methods: {
        meauSwitch() {

            this.meauShow = !this.meauShow;

            this.$emit('data-meau', this.meauShow);
        },

        navigatorTo(e) {
            this.$router.push(e.target.dataset.url)
        },
    }
}

</script>


<style lang="less" scoped>
.header {
    padding: 0 10vw;
    height: 50px;
    background-color: black;
    color: white;
    line-height: 50px;
    font-size: 16px;
    display: flex;
    font-family: Outfit, sans-serif;
    justify-content: space-between;

    .header-left {
        display: flex;
    }

    .contact-box {
        display: flex;
        font-size: 124px;

        .iconfont {
            font-size: 20px;
            padding: 0 10px;
            line-height: 50px;
        }
    }

    .iconfont {
        font-size: 10px;
        color: white;
    }
}

.nav {
    padding: 0 10vw;
    height: 65px;
    display: flex;
    justify-content: space-between;


    .meau-box {
        display: none;
    }


    .icon-ego-menu {
        line-height: 65px;
        width: 50px;
    }

    .meau-list {
        z-index: 9999;
        position: absolute;
        left: 0;
        width: 100%;
        background-color: white;
        /* display: none; */

        .meau-item {
            line-height: 40px;
            padding: 0 20px;

            .meau-item：hover {
                cursor: hand
            }
        }


    }

    .login-box {
        line-height: 65px;
        font-size: 18px;
        font-weight: 700;


    }

    .logo-box {


        .logo {
            // padding-top: 6px;
            width: 150px;
        }
    }

    .nav-list {
        display: flex;
        width: 50vw;
        // line-height: 65px;
        font-size: 15px;

        .nav-item {
            padding: 10px;
            font-weight: 700;
            line-height: 22px;
            text-align: center;
        }

        .nav-item:hover {
            cursor: pointer;
        }
    }

}

@media (max-width: 768px) {

    .header-left {
        font-size: 12px !important;
    }

    .phone {
        display: none !important;
    }

    .header {
        padding: 0 20px;
    }

    .nav {
        padding: 0 20px;
    }

    .nav-list {
        display: none !important;
    }

    .meau-box {
        display: block !important;
    }
}
</style>