<template>
    <div class="footer-box">
        <myHeader class="my-header"></myHeader>

        <div class="page-container">

            <div class="module-title">品牌背景</div>

            <div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;Vesitin
                    为香港注册商标品牌，品牌专注于营养膳食领域，致力于为消费者提供高品质、科学配方的营养产品。品牌秉持着对健康的执着追求和对品质的严格把控，不断研发创新，以满足不同消费者的需求。
                </div>
                <div>一：高端功效型营养膳食</div>
                <div>二：中端功效型营养膳食</div>
                <div>三：市场爆款营养膳食</div>
                <div>四：母婴类营养膳食</div>
                <div>五：中老年蛋白粉类营养膳食</div>
                <div>六：香港丸剂膳食系列</div>
                <div>七：特殊医学用途膳食系列</div>
            </div>


            <div class="module-title">品牌故事</div>


            <div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;在当今快节奏的生活中，人们越来越关注健康和营养。2022年Vesitin
                    的创始人团队深刻认识到营养膳食对于人们健康的重要性，于是毅然投身于营养膳食产品的研发与推广。我们遍访全球，寻找优质的原料和先进的生产技术，经过无数次的尝试和改进，终于打造出了一系列深受消费者喜爱的营养膳食产品。而孕妇生奶宝固体饮料，便是其中的明星产品之一。
                </div>
            </div>

            <div class="module-title">主推商品介绍</div>

            <div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;生奶宝固体饮料是 Vesitin
                    的拳头产品，采用优质的奶源和多种营养成分精心调配而成。它富含蛋白质、钙、维生素等多种营养物质，能够为孕妇人体提供充足的营养支持。在哺乳期很多孕妈妈没有营养丰富充足的奶水，Vesitin生奶宝通过原生态植物的提取与科学高配方的形成来补充奶水营养与丰富奶水的充足同时增强体质。
                </div>
            </div>

            <div class="module-title">团队介绍</div>

            <div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;Vesitin
                    拥有一支专业、高效、富有创新精神的团队。目前在江苏营销团队有70多人，团队成员包括营养专家5人、研发人员、市场营销人员10人，一类电商运营团队10人，客服售前团队12人，售后团队4人，二类新媒体短视频运营人员12人，线下展会营销10人等，在深圳办事处有负责产品研发与开拓的6人等
                    ，各自发挥自己的专业优势，共同为品牌的发展贡献力量。营养专家负责产品的配方研发和营养评估，确保产品的科学性和安全性；研发人员不断探索新的技术和原料，提升产品的品质和口感；市场营销人员则通过各种渠道和方式，将品牌和产品推广给更多的消费者。
                </div>
            </div>

            <div class="module-title">营销推广计划</div>

            <div>
                <div>1.线上推广</div>

                <div>
                    - 社交媒体营销：利用微博、微信、抖音等社交媒体平台，发布品牌故事、产品介绍、营养知识等内容，吸引用户关注。同时，开展互动活动，如抽奖、问答等，增加用户参与度和粘性。
                    - 电商平台推广：在各大电商平台开设官方旗舰店，优化店铺页面，提高产品曝光率。通过强付费打配合做输出，暂时拼多多旗舰店在陆续上新中
                    - 内容营销：撰写优质的博客文章、新闻稿等，发布在各大媒体平台上，提高品牌知名度和美誉度。</div>

                <div>2.线下推广</div>
                <div>
                    - 参加展会：参加各类营养食品展会，展示品牌和产品，与潜在客户进行面对面的交流和沟通。
                    - 举办活动：举办健康讲座、营养咨询等活动，向消费者普及营养知识，推广品牌和产品。
                    - 合作推广：与健身房、美容院、母婴店等相关机构进行合作，开展联合推广活动，扩大品牌影响力。
                </div>
            </div>

            <div class="module-title">其他平台上的运营策略</div>

            <div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;Vesitin
                    拥有一支专业、高效、富有创新精神的团队。目前在江苏营销团队有70多人，团队成员包括营养专家5人、研发人员、市场营销人员10人，一类电商运营团队10人，客服售前团队12人，售后团队4人，二类新媒体短视频运营人员12人，线下展会营销10人等，在深圳办事处有负责产品研发与开拓的6人等
                    ，各自发挥自己的专业优势，共同为品牌的发展贡献力量。营养专家负责产品的配方研发和营养评估，确保产品的科学性和安全性；研发人员不断探索新的技术和原料，提升产品的品质和口感；市场营销人员则通过各种渠道和方式，将品牌和产品推广给更多的消费者。
                </div>
            </div>

            <div class="module-title">品牌运营方案</div>

            <div>
                <div>
                    1.产品创新：不断研发新的产品，满足消费者日益增长的需求。同时，对现有产品进行升级和改进，提高产品的品质和竞争力。<br>
                    2.客户服务：建立完善的客户服务体系，及时回复用户的咨询和投诉，提高用户满意度。<br>
                    3.数据分析：通过对销售数据、用户行为数据等进行分析，了解用户需求和市场趋势，为品牌的发展提供决策依据。<br>
                    4.品牌建设：加强品牌建设，提升品牌知名度和美誉度。通过广告投放、公关活动等方式，树立品牌的良好形象。<br>
                </div>
            </div>
            <div class="module-title">货品价格</div>

            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;我们秉承:货真价实,不去虚高产品的价格,保持正常的零售价格,真材实料添加,销售亲民价格，以便品牌走的更长远。长久才是正确的经营思路。基本为在100-200区间单品
            </div>


        </div>


        <!-- <div>
            <el-anchor :offset="70">
                <el-anchor-link :href="`#${locale['basic-usage']}`">
                    {{ locale['Basic Usage'] }}
                </el-anchor-link>
            </el-anchor>
        </div> -->

        <div class="back-box" @click="backTop">
            <div class="iconfont icon-xiangshang1"></div>
        </div>
        <myFooter></myFooter>
    </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
    name: 'LoginPage',
    components: {
        myFooter,
        myHeader
    },
    data() {
        return {

        }
    },
    methods: {
        backTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style scoped lang="less">
.el-checkbox__input>.el-checkbox__inner {
    display: none;
}

.page-container {
    margin: 126px 0 30px 0;
    padding: 0 20%;
    background-color: white;
    line-height: 30px;

    .module-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 50px;
    }
}

.back-box {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    border-radius: 5px;
    background-color: black;
    color: white;
}

@media (max-width: 768px) {

    .page-container {
        margin-top: 126px;
        padding: 0 8%;
        background-color: white;
    }



}
</style>