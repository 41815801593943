<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>
@import url("../src/assets/font/iconfont.css");

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", Sans-serif;
}

.my-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
}
</style>
