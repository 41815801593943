
// router.js
import { createRouter, createWebHistory } from 'vue-router';
// import { createRouter } from 'vue-router';

// 引入组件
import HomePage from '../pages/HomePage.vue';
import LoginPage from '../pages/LoginPage.vue';
import AboutUs from '../pages/AboutUs.vue';
import FAQs from '../pages/FAQs.vue';
import ContactUs from '../pages/ContactUs.vue';
import Shop from '../pages/Shop.vue';
import Distribution from '../pages/Distribution.vue';
import Culture from '../pages/Culture.vue';
import ProductDetail from '../pages/ProductDetail.vue';

// 定义路由
const routes = [
  { path: '/', component: HomePage },
  { path: '/HomePage', component: HomePage },
  { path: '/AboutUs', component: AboutUs },
  { path: '/FAQs', component: FAQs },
  { path: '/ContactUs', component: ContactUs },
  { path: '/Shop', component: Shop },
  { path: '/Distribution', component: Distribution },
  { path: '/ProductDetail', component: ProductDetail },
  { path: '/Culture', component: Culture },
  { path: '/Login', component: LoginPage }
];

// 创建router实例
const router = createRouter({
  history: createWebHistory("#"),
  mode: 'hash',
  routes,
});

export default router;