<template>
    <div class="page">
        <myHeader class="my-header"></myHeader>


        <div class="container">
            <div class="model-1">

                <div class="p-left">
                    <div class="p-left-title">产品介绍</div>

                    <div class="p-left-item">
                        <div>所有产品</div>
                        <div class="iconfont icon-right"></div>
                    </div>
                    <div class="p-left-item">
                        <div>高端功效型</div>
                        <div class="iconfont icon-right"></div>
                    </div>
                    <div class="p-left-item">
                        <div>母婴莉莉斯</div>
                        <div class="iconfont icon-right"></div>
                    </div>
                    <div class="p-left-item">
                        <div>日销爆款</div>
                        <div class="iconfont icon-right"></div>
                    </div>
                    <div class="p-left-item">
                        <div>香港剂风堂</div>
                        <div class="iconfont icon-right"></div>
                    </div>
                </div>


                <div class="p-list">

                    <div class="p-item" v-for="(item, index) of list" :key="index">
                        <img class="p-item-img" :src="require('@/assets/' + item.img)" alt="vesitin">
                        <h4 class="p-item-title">{{ item.name }}</h4>
                        <div><span class="p-item-yj">$47.00</span><span class="p-item-xj">$40.00</span> </div>
                    </div>


                </div>
            </div>
        </div>


        <myFooter></myFooter>
    </div>


</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';


export default {
    name: 'HomePage',
    components: {
        myFooter,
        myHeader
    },
    data() {
        return {
            list: [
                { img: 'distr/P16.png', name: '胰岛果苦瓜胶囊Pro Max', price: '47.00', spec: '60粒/瓶', pattern: '每日两次，每次一粒', place: '香港' },
                { img: 'distr/P17.png', name: '茄子粉槲皮素肺康素胶囊Pro Max', price: '109.00', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
                { img: 'distr/P18.png', name: '西芹鹅肌肽尿酸复合片Pro Max', price: '47', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
                { img: 'distr/P19.png', name: '麦芽补硒复合速溶片Pro Max', price: '47', spec: '120片/瓶', pattern: '每日两次，每次一片', place: '香港' },
                { img: 'distr/P20.png', name: '纳豆激酶水蛭素胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P22.png', name: '高活性复合维生素B12Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P24.png', name: '神经酸胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P25.png', name: '褪黑素精华复合速溶片plus', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P26.png', name: '蒲公英忘忧草结节素胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P27.png', name: '熊果蔓越莓强力通石素胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P28.png', name: '奶蓟草姜黄护肝片Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P29.png', name: '月见草鹿胎素大豆异黄酮软护巢胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
                { img: 'distr/P30.png', name: 'NAD+PQQ麦角硫因细胞年轻态胶囊Pro Max', price: '47', spec: '30粒/瓶', pattern: '每日一次，每次一粒', place: '香港' },
            ],
        }
    },
    methods: {
        backTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style lang="less" scoped>
.page {
    .container {
        padding-top: 115px;
        width: 100%;
        margin: 0 auto;
        width: 1170px;

        .model-1 {
            padding-top: 50px;
            display: flex;
            justify-content: space-between;

            .p-left {
                width: 269px;

                .p-left-title {
                    font-size: 30px;
                    margin-bottom: 20px;
                }

                .p-left-item {
                    width: 269px;
                    background-color: #1a7567;
                    height: 50px;
                    line-height: 50px;
                    color: white;
                    display: flex;
                    padding: 0 10px;
                    justify-content: space-between;
                    margin-bottom: 5px;
                    border-radius: 10px;
                }
            }

            .p-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0 30px 30px 30px;

                &::after {
                    content: '';
                    flex: auto;
                }

                .p-item {
                    // height: 484px;
                    background: white;
                    margin: 10px;
                    padding: 10px;
                    font-size: 18px;
                    box-shadow: 0px 0px 10px #b0b0b0;
                    border-radius: 5px;

                    .p-item-yj {
                        text-decoration: line-through;
                    }

                    .p-item-xj {
                        font-weight: 700;
                        margin-left: 10px;
                    }

                    .p-item-title {
                        overflow: hidden;
                        height: 28px;
                        width: 232px;
                    }


                    .p-item-img {
                        width: 232px;
                        height: 232px;
                    }

                }
            }
        }
    }
}







@media (max-width: 768px) {
    .banner {
        width: 100vw;
    }


    .p-list {
        padding: 0;
        flex-wrap: wrap;

        .p-item {
            background: white;
            margin: 5px;
            padding: 10px;
            font-size: 18px;

            // width: 45vw !important;
            // height: auto !important;

            .p-item-yj {
                text-decoration: line-through;
            }

            .p-item-xj {
                font-weight: 700;
                margin-left: 10px;
            }

            .p-item-title {
                overflow: hidden;
                font-size: 14px;
                width: 40vw !important;
                height: auto !important;
            }


            .p-item-img {
                width: 150px !important;
                height: 150px !important;
            }

        }
    }

    .footer {
        height: 138px;
    }
}
</style>