<template>
    <div>
        <myHeader class="my-header" @data-meau="handleDataMeau"></myHeader>


        <div class="demo-collapse">
            <div class="page-title">
                Get In Touch
            </div>

            <div :class="meau ? 'meau-show' : ''" placeholder="Name">
                <input type="text" v-model="name" style="height: 45px;" class="input" placeholder="Name"><br><br>
                <input v-model="subject" style="height: 45px;" placeholder="Subject" class="input" /><br><br>
                <input v-model="phone" style="height: 45px;" placeholder="Phone" class="input" /><br><br>
                <textarea name="" id="" v-model="message" style="height: 180px;line-height: 45px;width: 100%;padding: 0 10px;" type="textarea" placeholder="Message"></textarea><br><br>


                <button type="primary" @click="sendTo" class="input"  value="send"
                    style="width: 100%;height: 45px;background-color: black;border: black; color: white;">send</button><br><br>
            </div>


        </div>

        <myFooter></myFooter>
    </div>

</template>

<script>
import myFooter from '../components/myFooter.vue';
import myHeader from '../components/myHeader.vue';
import { ElMessage } from 'element-plus'

export default {
    data() {
        return {
            name: '',
            subject: '',
            phone: '',
            message: '',

            meau: false,
        }
    },
    components: {
        myFooter,
        myHeader
    },

    mounted() {

    },

    methods: {
        sendTo() {
            if (this.name == '') {
                ElMessage.error('请输入昵称或名字')
                return;
            }
            if (this.subject == '') {
                ElMessage.error('请输入主题')
                return;
            }
            if (this.phone == '') {
                ElMessage.error('请输入手机号')
                return;
            }

            this.$axios({
                method: 'post',
                url: '/api/feedback/client/createFeedback',
                data: {
                    name: this.name,
                    subject: this.subject,
                    phone: this.phone,
                    message: this.message
                }
            }).then(res => {

                if (res.data.code === 0) {
                    ElMessage({
                        message: '添加成功!',
                        type: 'success',
                    })

                }

            })
        },

        handleDataMeau(data) {
            console.log(data);
            this.meau = data;
        }
    }
}

</script>

<style>
.demo-collapse {
    margin-top: 115px;
    padding: 0 30vw;
}

.meau-show {
    display: none;
}

.page-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 100px;
    height: 100px;
    text-align: center;
}

.input {
    width: 100%;
    padding: 0 10px;
}
</style>